import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Item, Label } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import md from '../modules/markdown';

export default class PostComponent extends Component {

    constructor(props) {
        super(props);
    }

    renderMarkdown(markdown) {
        return md.render(markdown);
    }

    render() {

        let publishedDate = null;
        if (this.props.post &&
            this.props.post.publishedDate) {

            let date = moment(this.props.post.publishedDate);
            publishedDate = date.format('MM-DD-YYYY');
        }

        let urlSafeTitle = this.props.post.urlSafeTitle || '';

        let tags = [];
        if (this.props.post.tags) {
            tags = this.props.post.tags.map((tag) => {
                return <Link to={`/tag/${tag.slug}`} key={`${tag.post_id}-${tag.slug}`}><Label>{tag.name}</Label></Link>
            });
        }

        return (
            <Item>
                <Item.Content>
                    <Item.Header as={Link} to={`/view/${urlSafeTitle}`}>{this.props.post.title}</Item.Header>
                    <Item.Meta>
                        <time className="post-date" dateTime={this.props.post.date}>{publishedDate}</time>
                    </Item.Meta>
                    <Item.Description dangerouslySetInnerHTML={{ __html: this.renderMarkdown(this.props.post.markdown) }} >
                    </Item.Description>
                    <Item.Extra>
                        {tags}
                    </Item.Extra>
                </Item.Content>
            </Item>
        );
    }
}

PostComponent.propTypes = {

};



