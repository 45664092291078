import React, { useState, useEffect } from 'react';
import { Item } from 'semantic-ui-react';
import PostComponent from '../components/PostComponent';
import {API_URL_GENERATOR} from "../constants/routes";

export default ({match}) => {
    const [post, setPost] = useState(null);
    useEffect( () => {
        getPost(match.params.postTitle);
    }, [match.params.postTitle]);

    const getPost = async (postTitle) => {
        const url = API_URL_GENERATOR.POST(postTitle);
        const response = await fetch(url);
        const post = await response.json();
        setPost(post);
    };

    if (post){
       return (<div>
           <Item.Group divided>
               <PostComponent post={post}/>
           </Item.Group>
        </div>
       );
    }
    else {
        return (<div/>);
    }
};
