import {combineReducers} from 'redux';
import postCount from './postCount';
import newPost from './newPost';
import posts from './posts';

const rootReducer = combineReducers({
    postCount,
    newPost,
    posts
});
export default rootReducer;