import ActionTypes from '../constants/actionTypes';
export default function posts(state = '', action) {
    switch (action.type) {
        case ActionTypes.REQUEST_POST_FOR_PAGE_FETCHING:
            return {
                isFetchingPosts: true
            };
        case ActionTypes.RECEIVE_POST_FOR_PAGE:
            return {
                isFetchingPosts: false,
                posts: action.posts
            };
        default:
            return state;
    }
}