import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import rootReducer from '../reducers';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas';


const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState) {
    const store = createStore( //https://github.com/reactjs/redux/blob/master/docs/api/createStore.md
        rootReducer, //reducer
        initialState, //optional initialState
        compose(
            applyMiddleware( //enhancer
                sagaMiddleware
            ),
            window.devToolsExtension ? window.devToolsExtension() : f => f
        )
    );

    sagaMiddleware.run(rootSaga);
    // Required for replaying actions from devtools to work
    return store;
}