import ActionTypes from '../constants/actionTypes';
export default function postCount(state = '', action) {
    switch (action.type) {
        case ActionTypes.REQUEST_POSTCOUNT_FETCHING:
            return {
                isFetchingPostCount: true
            };
        case ActionTypes.RECEIVE_POSTCOUNT:
            return {
                isFetchingPostCount: false,
                postCount: action.postCount
            };
        default:
            return state;
    }
}