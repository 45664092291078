import React from 'react';
import PostComponent from '../../components/PostComponent';
let aboutMd = require('./about.md');

export default () => {
    let about = {
        markdown: aboutMd.default,
        publishedDate: ''
    };

    return (
        <div>
            <PostComponent post={about}/>
        </div>
    );
}