
export default {

    REQUEST_POSTCOUNT:"REQUEST_POSTCOUNT",
    RECEIVE_POSTCOUNT:"RECEIVE_POSTCOUNT",
    REQUEST_POSTCOUNT_FETCHING: "REQUEST_POSTCOUNT_FETCHING",

    REQUEST_POSTS_FOR_PAGE:"REQUEST_POSTS_FOR_PAGE",
    RECEIVE_POST_FOR_PAGE:"RECEIVE_POST_FOR_PAGE",
    REQUEST_POST_FOR_PAGE_FETCHING: "REQUEST_POST_FOR_PAGE_FETCHING",

    NEWPOST_RESET: "NEWPOST_RESET",
    NEWPOST_CHANGED: "NEWPOST_CHANGED",
    NEWPOST_SUBMITTED: "NEWPOST_SUBMITTED",
    NEWPOST_SAVING: "NEWPOST_SAVING",
    NEWPOST_SAVED: "NEWPOST_SAVED"
};