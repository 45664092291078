import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { Form, Button, TextArea, Header, Item } from 'semantic-ui-react';
import { newPostTitleChanged, newPostMarkdownChanged, newPostSubmit, newPosrtReset, newPostPasswordChanged } from '../actions';

import PostComponent from '../components/PostComponent';
import PaginationComponent from '../components/PaginationComponent';


class SubmitContainer extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        this.props.newPosrtReset();
    }

    handleTitleChanged(e){
        this.props.newPostTitleChanged(e.target.value);
    }

    handleMarkdownChanged(e){
        this.props.newPostMarkdownChanged(e.target.value);
    }

    handlePasswordChanged(e) {
        this.props.newPostPasswordChanged(e.target.value);
    }

    handleSubmit(e){
        this.props.newPostSubmit();
        e.preventDefault();
    }

    render() {


        return (

            <div>
                <Form onSubmit={this.handleSubmit.bind(this)}>
                    <Form.Field>
                        <label>Password:</label>
                        <input
                            type='password'
                            value={this.props.newPost.password}
                            onChange={this.handlePasswordChanged.bind(this)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Title:</label>
                        <input
                            value={this.props.newPost.title}
                            onChange={this.handleTitleChanged.bind(this)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Body:</label>
                        <TextArea
                            value={this.props.newPost.markdown}
                            onChange={this.handleMarkdownChanged.bind(this)}
                        />
                    </Form.Field>

                    <Button type='submit'>Submit</Button>
                </Form>

                <Header as='h2' className='submit-preview'>Preview</Header>
                <Item.Group divided>
                    <PostComponent post={this.props.newPost} />
                </Item.Group>
            </div>
        );


    }
}

SubmitContainer.propTypes = {

};


function mapStateToProps(state) {
    return {
        newPost: state.newPost || {title: '', markdown: '', urlSafeTitle: ''}
};
}


export default connect(mapStateToProps, {
    newPostTitleChanged,
    newPostMarkdownChanged,
    newPostSubmit,
    newPosrtReset,
    newPostPasswordChanged
})(SubmitContainer);

