import { put, takeEvery, takeLatest, all, fork, call, apply, select } from 'redux-saga/effects'
import ActionTypes from '../constants/actionTypes';
import { receivePostCount, fetchingPostCount, receivePostsForPage, fetchingPostsForPage, newPostSaving, newPostSaved} from '../actions';
import { API_URL_GENERATOR } from "../constants/routes";


export function* newPostSubmitHandler(){

    yield put(newPostSaving());
    let newPost = yield select((state) => state.newPost);

    let response = yield call(fetch, '/api/post', {method: 'POST', body: JSON.stringify(newPost), headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    } });

    if (response.ok){
        yield put(newPostSaved());
         //yield put(push('/'));
    }
    else {
        alert('no worky');
    }
}

function sleep (ms) {
    return new Promise(res => {
        setTimeout(res, ms)
    })
}

export const requestPostCount = async (dispatch, getState, tag) => {

    let url = null;
    if (tag) {
        url = API_URL_GENERATOR.POST_COUNT_FOR_TAG(tag);
    } else {
        url = API_URL_GENERATOR.POST_COUNT();
    }

    let postCount = getState().postCount;

    if ((!postCount ||
        postCount.postCount === 0) &&
        !postCount.isFetchingPostCount) {

        dispatch(fetchingPostCount());
        const response = await fetch(url);
        const postCountFromCall = await response.json();
        dispatch(receivePostCount(postCountFromCall));
    }
};

export const requestPosts = async (dispatch, getState, tag, pageNumber) => {

    let url = null;
    if (tag) {
        url = API_URL_GENERATOR.POSTS_FOR_TAG(tag, pageNumber);
    } else {
        url = API_URL_GENERATOR.POSTS(pageNumber);
    }

    dispatch(fetchingPostsForPage());
    let response = await fetch(url);
    let posts = await response.json();
    dispatch(receivePostsForPage(posts));
};

export default function* rootSaga() {
    yield takeLatest(ActionTypes.REQUEST_POSTS_FOR_PAGE, requestPosts);
    yield takeLatest(ActionTypes.REQUEST_POSTCOUNT, requestPostCount);
    yield takeEvery(ActionTypes.NEWPOST_SUBMITTED, newPostSubmitHandler);
}