import React, { Component } from 'react';
import {
    Link, withRouter
} from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import moment from 'moment';

class Footer extends Component {

    render() {
        const currentDay = moment();
        return (
            <Grid columns={1} centered>
                <Grid.Row>
                    <Grid.Column width={3} textAlign='center'>
                        <section className="copyright"><Link to="/">DevWi LLC</Link> © {currentDay.year()}</section>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={1} textAlign='center'>
                        <section><a href="/rss">RSS</a></section>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default withRouter(Footer);