import React, { useState, useEffect } from 'react';
import { Table } from 'semantic-ui-react';
import moment from 'moment';
import {API_URL_GENERATOR} from "../constants/routes";

export default () => {
    const [groups, setGroups] = useState([]);
    useEffect(() => {
        getGroups();
    });

    const getGroups = async () => {
        const response = await fetch(API_URL_GENERATOR.GROUPS());
        const groups = await response.json();
        setGroups(groups);
    };

    let groupsComponents = [];
    groups.forEach((group) => {

        let start = '';
        let title = null;
        if (group.event) {
            let startDate = moment(group.event.start);
            start = startDate.format('MM-DD-YYYY');
            title = (
                <a href={group.event.eventUrl}>{group.event.title}</a>
            );
        }

        groupsComponents.push(
           <Table.Row key={group.meetupGroupId}>
               <Table.Cell><a href={group.url}>{group.name}</a></Table.Cell>
               <Table.Cell>{title}</Table.Cell>
               <Table.Cell>{start}</Table.Cell>
           </Table.Row>);
    });

    return (
        <Table celled striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Meetup Name</Table.HeaderCell>
                    <Table.HeaderCell>Upcoming Event</Table.HeaderCell>
                    <Table.HeaderCell>Upcoming Event Date</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {groupsComponents}
            </Table.Body>
        </Table>
    );
}
