import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import {
    Route,
    Switch,
    withRouter,
    Redirect
} from 'react-router-dom';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Grid } from 'semantic-ui-react';

import Header from '../components/layout/header';
import Footer from "../components/layout/footer";
import CalendarContainer from './CalendarContainer';
import PostListContainer from './PostListContainer';
import AboutContainer from './About/AboutContainer';
import PostContainer from './PostContainer';
import SubmitContainer from './SubmitContainer';
import SlackContainer from "./Slack/SlackContainer";
import GroupsContainer from './GroupsContainer';
import EventSubmitContainer from './EventSubmitContainer';

class Root extends Component {

    logPageView() {
        _paq.push(['setCustomUrl', '/' + window.location.pathname]);
        _paq.push(['trackPageView']);
        // ReactGA.set({ page: window.location.pathname });
        // ReactGA.pageview(window.location.pathname);
    }

    render() {
        this.logPageView();
        return (
            <div>
                <Header />
                <TransitionGroup className="main-route-frame">
                    <CSSTransition classNames="fade"  timeout={{
                        enter: 300,
                        exit: 200,
                    }}
                    key={this.props.location.key}
                    appear
                    >
                        <div className="main-route-content">
                            <Grid columns={1} centered>
                                <Grid.Row>
                                    <Grid.Column width={12}>
                                        <Switch location={this.props.location}>
                                            <Route path="/" exact render={() => {
                                                return (
                                                    // <Suspense fallback={(<div>Waiting...</div>)} >
                                                    //     <PostListContainer/>
                                                    // </Suspense>
                                                    <Redirect to="/calendar" />
                                                );
                                            }} />
                                            <Route path="/groups" component={GroupsContainer}/>
                                            <Route path="/calendar" component={CalendarContainer}/>
                                            <Route path="/page/:pageNumber" exact render={() => {
                                                return (
                                                    <Suspense fallback={(<div>Waiting...</div>)} >
                                                        <PostListContainer/>
                                                    </Suspense>
                                                );
                                            }} />
                                            <Route path="/tag/:tag/page/:pageNumber" component={PostListContainer} />
                                            <Route path="/tag/:tag" component={PostListContainer} />
                                            <Route path="/view/:postTitle" component={PostContainer} />
                                            <Route path="/about" component={AboutContainer} />
                                            <Route path="/slack" component={SlackContainer} />
                                            <Route path="/submit" component={SubmitContainer} />
                                        </Switch>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Footer />
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </div>

        );
    }
}


export default withRouter(Root);