import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Header, Modal } from 'semantic-ui-react';
import { get } from 'lodash';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { Calendar, momentLocalizer } from 'react-big-calendar'
const localizer = momentLocalizer(moment)

export default () => {
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [start, setStart] = useState(moment().date(1).add('1', 'day').format('YYYY-MM-DD'));
    const [end, setEnd] = useState(moment().date(1).add(1, 'month').add(1, "day").format('YYYY-MM-DD'));
    const [events, setEvents] = useState([]);
    useEffect(() => {
        getEvents();
    }, [start, end]);
    const getEvents = async () => {
        const response = await fetch(`/api/events?start=${start}&end=${end}`);
        const results = await response.json();
        setEvents(results);
    };

    const onRangeChange = (range) => {
        setStart(moment(range.start).format('YYYY-MM-DD'));
        setEnd(moment(range.end).format('YYYY-MM-DD'));
    };

    const handleClose = () => {
        setSelectedEvent(null);
    };



    return (
    <div className="event-calender">
        <Modal closeIcon
               open={(selectedEvent !== null)}
               onClose={handleClose}>
            <Header icon='users' content='Event Details' />
            <Modal.Content>
                <Header as='h3'>
                    <a href={get(selectedEvent, 'url', '')}>
                        {get(selectedEvent, 'title', '')}
                    </a>
                </Header>
                <Header as='h5'>
                    Start:
                </Header>
                {moment(get(selectedEvent, 'start', null)).format('L LT')}
                <Header as='h5'>Description:</Header>
                <div dangerouslySetInnerHTML={{__html: get(selectedEvent, 'description', '')}} />
            </Modal.Content>
        </Modal>
        <Calendar
            localizer={localizer}
            events={events}
            popup
            views={['month']}
            startAccessor="start"
            endAccessor="end"
            onSelectEvent={(event, e) => setSelectedEvent(event)}
            onRangeChange={onRangeChange}
        />
    </div>
    );
}
