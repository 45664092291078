export const API_URLS = {
    POST_COUNT: `/api/posts/count`,
    POST_COUNT_FOR_TAG: `/api/:tag/posts/count`,
    POSTS: `/api/posts/:pageNumber`,
    POSTS_FOR_TAG: `/api/:tag/posts/:pageNumber`,
    POST: `/api/post/:postTitle`,
    GROUPS: `/api/groups`
};

export const API_URL_GENERATOR = {
    POST_COUNT: () => API_URLS.POST_COUNT,
    POST_COUNT_FOR_TAG: tag => API_URLS.POST_COUNT_FOR_TAG.replace(':tag', tag),
    POSTS: pageNumber => API_URLS.POSTS.replace(':pageNumber', pageNumber || ''),
    POSTS_FOR_TAG: (tag, pageNumber) => API_URLS.POSTS_FOR_TAG.replace(':tag', tag).replace(':pageNumber', pageNumber || ''),
    POST: (postTitle) => API_URLS.POST.replace(':postTitle', postTitle),
    GROUPS: () => API_URLS.GROUPS
};