import "@babel/polyfill";
import 'semantic-ui-css/semantic.min.css';
import '../css/style.css';


import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import { BrowserRouter } from "react-router-dom";
import Root from './containers/Root';

const store = configureStore();

render(
    <Provider store={store}>
        <BrowserRouter>
            <Root />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root'));

