import ActionTypes from '../constants/actionTypes';



export const requestPostCount = (tag) => { return {type: ActionTypes.REQUEST_POSTCOUNT, tag}};
export const fetchingPostCount = () => { return {type: ActionTypes.REQUEST_POSTCOUNT_FETCHING}};
export const receivePostCount = (postCount) => { return {type: ActionTypes.RECEIVE_POSTCOUNT, ...postCount} };

export const requestPostsForPage = (pageNumber, tag) => { return {type: ActionTypes.REQUEST_POSTS_FOR_PAGE, pageNumber, tag}};
export const fetchingPostsForPage = () => { return {type: ActionTypes.REQUEST_POST_FOR_PAGE_FETCHING}};
export const receivePostsForPage = (posts) => { return {type: ActionTypes.RECEIVE_POST_FOR_PAGE, posts} };


export const newPostTitleChanged = title => { return {type: ActionTypes.NEWPOST_CHANGED, payload: {title: title}} };
export const newPostMarkdownChanged = markdown => {return {type: ActionTypes.NEWPOST_CHANGED, payload: {markdown: markdown}}};
export const newPostPasswordChanged = password => {return {type: ActionTypes.NEWPOST_CHANGED, payload: {password: password}}};
export const newPosrtReset = () => {return {type: ActionTypes.NEWPOST_RESET}};
export const newPostSubmit = () => {return {type: ActionTypes.NEWPOST_SUBMITTED} };
export const newPostSaving = () => {return {type: ActionTypes.NEWPOST_SAVING} };
export const newPostSaved = () => {return {type: ActionTypes.NEWPOST_SAVED} };