import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { Item } from 'semantic-ui-react';
import _ from 'lodash';

import { requestPostCount, requestPosts } from '../sagas/';

import PostComponent from '../components/PostComponent';
import PaginationComponent from '../components/PaginationComponent';

import { useAsyncActions } from '../modules/useAsyncAction';

export const PostListContainer = ({match, posts, postCount}) => {
    useAsyncActions([
        { fn: requestPostCount, args: [match.params.tag] },
        { fn: requestPosts, args: [match.params.tag, match.params.pageNumber]}
    ]);

    let postsComponents = [];
    posts.posts.forEach(post => {
        postsComponents.push(<PostComponent key={post.id} post={post}/>)
    });

    return (<div>
        <Item.Group divided>
            {postsComponents}
        </Item.Group>
        <PaginationComponent postCount={postCount.postCount} pageNumber={Number(match.params.pageNumber) || 0}/>
    </div>);
};

// PostListContainer.propTypes = {
//     requestPostCount: PropTypes.func.isRequired
// };


function mapStateToProps(state) {
    return {
        postCount: _.get(state, 'postCount', null),
        posts: _.get(state, 'posts', null)
    };
}


export default withRouter(connect(mapStateToProps, {})(PostListContainer));

