import ActionTypes from '../constants/actionTypes';
export default function newPost(state = '', action) {
    switch (action.type) {
        case ActionTypes.NEWPOST_RESET:
            return {
                title: '',
                markdown: ''
            };
        case ActionTypes.NEWPOST_CHANGED:
            return Object.assign({}, state, action.payload);
        default:
            return state;
    }
}