import React, { Component } from 'react';
import {
    Link, withRouter
} from 'react-router-dom';
import { Grid, Menu, Dropdown } from 'semantic-ui-react';
import logoPath from '../../../images/devwi-logo.svg';

class Header extends Component {

    render() {
        return (
            <Grid columns={1} centered>
                <Grid.Row>
                    <Grid.Column width={12} textAlign="center">
                        <Link to="/">
                            <img className="page-logo" src={logoPath} alt="devwi logo" />
                        </Link>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={12} textAlign="center">
                        <Menu size='huge' borderless={true} compact={true} secondary>
                            {/*<Menu.Item as={Link} to="/">*/}
                            {/*    Home*/}
                            {/*</Menu.Item>*/}
                            <Dropdown item text='Community'>
                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to="/groups">Groups</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/calendar">Calendar</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/slack">Slack</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Menu.Item as={Link} to="/about">
                                About
                            </Menu.Item>
                        </Menu>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default withRouter(Header);