import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid, Menu, Dropdown } from 'semantic-ui-react';

export default class PaginationComponent extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const postCount = this.props.postCount;
        const pageNumber = this.props.pageNumber || 1;
        const totalPages = postCount / 10;

        let newPost = null;
        let oldPost = null;

        if (pageNumber !== 1) {
            newPost = (
                <Link key="newer" to={"/page/" + (pageNumber - 1)} className="newer-posts" ><span>←</span> Newer Posts</Link>
            );
        }

        if (pageNumber < totalPages){
            oldPost = (
                <Link key="older" to={"/page/" + (pageNumber + 1)} className="older-posts" >Older Posts <span>→</span></Link>
            );
        }



        return (
           <div className="pagination">
               <Grid columns={12} centered>
                   <Grid.Row>
                       <Grid.Column width={3} floated="left">
                           {newPost}
                       </Grid.Column>
                       <Grid.Column width={3} floated="right">
                           {oldPost}
                       </Grid.Column>
                   </Grid.Row>
               </Grid>
           </div>
        );
    }
}

PaginationComponent.propTypes = {
    postCount: PropTypes.number,
    pageNumber: PropTypes.number
};



